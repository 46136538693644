import { modelT } from "../../redux/features/ui/ui.types";

export const modelsOttomans: modelT[] = [
  {
    img: "/images/assets/ClassicSofa/Ottoman.png",
    label: "Minsky Set",
    nameValueThreekit: "Minsky Ottomans",
  },
  {
    img: "/images/assets/ClassicSofa/Ottoman.png",
    label: "Haxel Storage Footstool",
    nameValueThreekit: "Haxel Storage Footstool",
    subcategory: "Ottomans",
  },
  {
    img: "/images/assets/ClassicSofa/Ottoman.png",
    label: "Dixy Storage Footstool",
    nameValueThreekit: "Dixy Storage Footstool",
    subcategory: "Ottomans",
  },
  {
    img: "/images/assets/ClassicSofa/Ottoman.png",
    label: "Bell Storage Ottoman",
    nameValueThreekit: "Bell Storage Ottoman",
    subcategory: "Ottomans",
  },

  {
    img: "/images/assets/ClassicSofa/Ottoman.png",
    label: "Medium Rectangle",
    nameValueThreekit: "Medium Rectangle",
    subcategory: "Ottomans",
  },
  {
    img: "/images/assets/ClassicSofa/Ottoman.png",
    label: "Medium Square Ottoman",
    nameValueThreekit: "Medium Square Ottoman",
    subcategory: "Ottomans",
  },
  {
    img: "/images/assets/ClassicSofa/Ottoman.png",
    label: "Large Circle",
    nameValueThreekit: "Large Circle",
    subcategory: "Ottomans",
  },
  {
    img: "/images/assets/ClassicSofa/Ottoman.png",
    label: "Large Rectangle",
    nameValueThreekit: "Large Rectangle",
    subcategory: "Ottomans",
  },
  {
    img: "/images/assets/ClassicSofa/Ottoman.png",
    label: "Medium Circle",
    nameValueThreekit: "Medium Circle",
    subcategory: "Ottomans",
  },
  {
    img: "/images/assets/ClassicSofa/Ottoman.png",
    label: "Large Square Ottoman",
    nameValueThreekit: "Large Square Ottoman",
    subcategory: "Ottomans",
  },
];
